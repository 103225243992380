<template>
  <div class="footer-naga">
      <div class="container">
          <div class="footer-naga-content">
              <div class="footer-naga-content-copyrights">
                  <p>Copyright © 2020 – All rights reserved.</p>
                  <p>NAGA is a trademark of The NAGA Group AG, a German based FinTech company publicly listed on the Frankfurt Stock Exchange | WKN: A161NR | ISIN: DE000A161NR7.</p>
              </div>

              <div class="footer-links-naga">
                  <ul>
                      <li><a href="" target="_blank">Privacy Policy <span class="ul-li-spacer-naga">|</span></a></li>
                      <li><a href="" target="_blank">Cookie Policy <span class="ul-li-spacer-naga">|</span></a></li>
                      <li><a href="" target="_blank">Terms and Conditions</a></li>
                  </ul>

                  <p>The website is owned by The NAGA Group AG and operated by NAGA Markets Europe Ltd which is authorised and regulated by the Cyprus Securities and Exchange Commission (CySEC) under licence No. 204/13.</p>
              </div>
    
              <div class="registration-naga">
                  <p><strong> Registrations: </strong>FCA ref. no. 609499 | BaFin reg. 135203 | Consob reg. 3844 | CNMV reg. 3591</p>
                  <p>The NAGA Group AG is the holding company of NAGA Markets Ltd, NAGA Technology GmbH and NAGA Global Ltd.</p>
              </div>

              <div class="trade-responsibly-naga">
                  <p><strong>Trade Responsibly: </strong> CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 77.95% of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Past performance of a NAGA community member is not a reliable indicator of his future performance. Content on NAGA's social trading platform is generated by the members of its community and does not contain advice or recommendations by or on behalf of NAGA.</p>


                  <p>Trading with NAGA Trader by following and/or copying or replicating the trades of other traders involves high levels of risks, even when following and/or copying or replicating the top-performing traders. Such risks include the risk that you may be following/copying the trading decisions of possibly inexperienced/unprofessional traders, or traders whose ultimate purpose or intention, or financial status may differ from yours. Before making an investment decision, you should rely on your own assessment of the person making the trading decisions and the terms of all the legal documentation.</p>
              </div>

              <div class="restricted-region-naga">
                  <p><strong>Restricted regions:</strong> NAGA Markets Europe Ltd offers services to residents within the European Economic Area, excluding Belgium. NAGA Markets Europe Ltd does not provide investment</p>
              </div>
          </div>
      </div>


   
  </div>

</template>

<script>
export default {

}
</script>

<style>
    .footer-naga {
        border-top: 1px solid rgb(202, 202, 202);
        padding-top: 20px;
        max-width: 1640px;
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
    }

    .footer-naga-content-copyrights p:nth-child(1) {
        font-size: 16px;
        margin-bottom: 3px;
        font-weight: bold;
    }

    .footer-links-naga {
        margin-top: 25px;
    }

    .footer-links-naga ul {
        display: flex;
        margin-bottom: 3px;
    }
    .footer-links-naga ul li a {
        text-decoration: underline;
        color: #000;
    }

    .ul-li-spacer-naga {
        margin-left: 5px;
    }
    .footer-links-naga ul li  {
        margin-right: 10px;
    }

    .registration-naga {
        margin-top: 25px;
    }
    .registration-naga p:nth-child(2) {
        margin-top: 25px;
    }

    .trade-responsibly-naga {
        margin-top: 25px;
    }
    .trade-responsibly-naga p:nth-child(2) {
        margin-top: 25px;
    }

    .restricted-region-naga {
        margin-top: 25px;
        margin-bottom: 25px;
    }

   @media(max-width:1024px){
       .footer-naga p {
           font-size: 14px;
       }

       .footer-links-naga ul li a {
           font-size: 14px;
    }
   }

   @media(max-width:768px){
       .ul-li-spacer-naga {
           display: none;
       }

       .footer-links-naga ul li a {
           font-size: 10px;
    }
   }
</style>